import { Component, StrictMode } from "react";
import axios from "axios";

var baseUrl = "https://api.avidhilda.com/api/yalda/";

export default class App extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      isCash: true,
      seats: 10,
      tables: [],
      isTables: false,
      table: null,
      error: "",
      succes: null,
    };
  }

  user: any = { name: "", email: "", phone: "" };

  _onSubmit = async () => {
    const { isCash, loading, seats, table }: any = this.state;
    if (loading) return;
    this.setState({ error: "" });
    if (this.user.name === "") {
      this.setState({ error: "Enter your name" });
      return;
    }
    if (this.user.email === "") {
      this.setState({ error: "Enter your email" });
      return;
    }
    if (this.user.phone === "") {
      this.setState({ error: "Enter your phone number" });
      return;
    }
    if (this.user.email.split(".").length < 2) {
      this.setState({ error: "Not a valid email" });
      return;
    }
    if (this.user.email.split("@").length < 2) {
      this.setState({ error: "Not a valid email" });
      return;
    }
    if (table === null) {
      this.setState({ error: "Select Your table" });
      return;
    }
    this.setState({ loading: true }); //
    await axios
      .post(baseUrl + "bookTable", {
        id: table.id,
        name: this.user.name,
        email: this.user.email,
        mobile: this.user.phone,
        seats: seats,
        payment_type: isCash ? "cash" : "online",
        payment_id: "",
      })
      .then(async (res: any) => {
        // await axios
        //   .post(baseUrl + "verifyEmail", {
        //     id: res?.data?.data?.id,
        //     name: this.user.name,
        //     email: this.user.email,
        //   })
        //   .then(() => {
        this.setState({ succes: table });
        this._getTables();
        // })
        // .catch(() =>
        //   this.setState({ error: "Error on booking, try again!" })
        // );
      })
      .catch(() => this.setState({ error: "Error on booking, try again!" }));
    this.setState({ loading: false });
  };

  _getTables = () => {
    axios
      .get(baseUrl + "getTables")
      .then(async (res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          const el = res.data.data[i];
          if (el?.status === "reserved") {
            var dateObject = new Date(el?.reserved_on);
            if (!isNaN(dateObject.getTime())) {
              dateObject.setDate(dateObject.getDate() + 3);
              if (dateObject > new Date()) {
                el.availableDate = dateObject?.toString()?.substring(0, 15);
              } else el.status = "available";
              console.log(el);
            }
          }
        }
        this.setState({ tables: res.data.data });
      })
      .catch(() => this.setState({ error: "Error on loading the data" }));
  };

  componentDidMount() {
    if (window.location.hostname === "localhost")
      baseUrl = "https://api-dev.avidhilda.com/api/yalda/";
    this._getTables();
  }

  _getBookingStatusMsg = (table: any) => {
    if (table?.status === "available")
      return (
        <div style={{ color: "green" }}>
          We are pleased to inform you that the chosen seat is currently
          available.
        </div>
      );
    if (table?.status === "reserved")
      return (
        <div style={{ color: "orange" }}>
          The selected seat is reserved.
          <br />
          It will available on <b>{table.availableDate}</b>, if the tickit is
          not confirmed by the reserved person <br />
        </div>
      );
    if (table?.status === "booked")
      return (
        <div style={{ color: "red" }}>
          We regret to inform you that the selected seat has already been booked
          by another person.
        </div>
      );
  };

  render() {
    const { loading, seats, tables, isTables, table, error }: any = this.state;
    const { succes }: any = this.state;
    return (
      <div className="body">
        <div className="leftBody">
          <button
            onClick={() =>
              window.open("https://avidhilda.com/events/details/yaldanight")
            }
          >
            Learn More
          </button>
        </div>
        <div className="middleBody">
          <div className="bg" />
          <div className="title">BOOK YOUR TICKETS FOR YALDA NIGHT</div>
          <div className="desc">
            Fill the Form and make payment to book your tickets.
          </div>
          <input
            placeholder="Name"
            onChange={(e) => (this.user.name = e.target.value)}
          />
          <input
            placeholder="Email"
            onChange={(e) => (this.user.email = e.target.value)}
          />
          <input
            placeholder="Mobile"
            type="number"
            onChange={(e) => (this.user.phone = e.target.value)}
          />
          <div style={{ display: "flex", margin: "15px 0" }}>
            {/* <select placeholder="Number Of Person" style={{ width: "100%" }}>
              <option style={{color:'red'}}>Select Table</option>
              {tables.map((it: any, k: any) => (
                <option value={it?.id}>{it?.table_name}</option>
              ))}
            </select> */}
            <button
              className="arrowDown"
              onClick={() => this.setState({ isTables: true })}
            >
              {table ? "Change Table" : "Select Table"}
            </button>
            <div className="counter">
              <span>Seats</span>
              <div
                onClick={() => {
                  if (seats !== 1) this.setState({ seats: seats - 1 });
                }}
              >
                -
              </div>
              <span>{seats}</span>
              <div
                onClick={() => {
                  if (seats !== 10) this.setState({ seats: seats + 1 });
                }}
              >
                +
              </div>
            </div>
          </div>
          {table && (
            <StrictMode>
              <b>Selected</b>
              <div style={{ color: "gray", fontSize: 12, lineHeight: 2 }}>
                <div>
                  <b>Name</b> : {table.table_name}
                </div>
                <div>
                  <b>Type</b> : {table.category_name}
                </div>
                <div>
                  <b>Price</b> : {table.price}
                </div>
                <div>
                  <b>Status</b> : {table.status}
                </div>
                {this._getBookingStatusMsg(table)}
              </div>
            </StrictMode>
          )}
          <br />
          {/* <b>Payment</b>
          <div className="cashORpay">
            <button
              className={"cash" + (isCash ? " selected" : "")}
              onClick={() => this.setState({ isCash: true })}
            >
              CASH
            </button>
            <button
              className={"online" + (!isCash ? " selected" : "")}
              onClick={() => this.setState({ isCash: false })}
            >
              PAY NOW
            </button>
          </div> */}
          {error !== "" && (
            <div className="paymentWarning" style={{ color: "red" }}>
              {error}
            </div>
          )}
          <button
            className="selected"
            style={loading ? { opacity: 0.5 } : {}}
            disabled={loading}
            onClick={() => this._onSubmit()}
          >
            BOOK THE TICKETS
          </button>
          <div className="paymentWarning"></div>
        </div>
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        <div className="rightBody">
          <div className="map" />
          <b style={{ fontSize: 14, color: "gray" }}>Legend</b>
          <div className="labels">
            <div className="diamond">DIAMOND</div>
            <div className="gold">GOLD</div>
            <div className="silver">SILVER</div>
          </div>
          <div style={{ fontSize: 12, color: "#aaa", textAlign: "center" }}>
            Copyright © Avid Hilda 2023 | +971 4 568 1119 | info@avidhilda.com
          </div>
        </div>
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        {isTables && (
          <div
            className="tablePopUp"
            onClick={() => this.setState({ isTables: false })}
          >
            <div className="popup">
              <div className="regHeader">
                Select Table
                <div
                  className="closeBtn"
                  onClick={() => this.setState({ isTables: false })}
                />
              </div>
              <div className="popBody">
                {tables.map((it: any, k: any) => (
                  <div
                    className="tableEach"
                    key={k}
                    onClick={() => this.setState({ table: it })}
                  >
                    <div>
                      <b>{it?.table_name}</b>&nbsp;&nbsp;
                      <span style={{ color: "gray" }}>
                        {it?.price}&nbsp;
                        {it?.currency}
                      </span>
                    </div>
                    <div>
                      {it.status === "available" ? (
                        <div style={{ color: "green" }}>Available</div>
                      ) : it.status === "reserved" ? (
                        <div style={{ color: "orange" }}>Reserved</div>
                      ) : it.status === "booked" ? (
                        <div style={{ color: "red" }}>Booked</div>
                      ) : (
                        it.status
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {succes && (
          <div
            className="tablePopUp"
            onClick={() => {
              this.setState({ succes: false });
              window.location.reload();
            }}
          >
            <div className="popup">
              {/* <div className="regHeader">
                APPLICATION FORM
                <div
                  className="closeBtn"
                  onClick={() => {
                    this.setState({ succes: false });
                    window.location.reload();
                  }}
                />
              </div> */}
              <div style={{ fontSize: 12, lineHeight: 2, padding: 20 }}>
                {/* {this._getBookingStatusMsg(succes.status)} */}
                {succes?.status === "available" && (
                  <center>
                    <b style={{ fontSize: 20 }}>
                      Thank you for selecting your seat!
                    </b>
                    <br />
                    <br />
                    <div style={{ fontSize: 15 }}>
                      Please await a call from one of our avidhidha executives
                      who will verify your identity and confirm your tickets.
                    </div>
                    <br />
                    <b style={{ color: "#987F51" }}>
                      Kindly check your email for the verification link.
                    </b>
                    <br />
                    <br />
                    <div style={{ color: "gray" }}>
                      Your booking details will be sent to you via email shortly
                      after the completion of the booking process.
                      <br />
                      This is a standard procedure to ensure the security of
                      your booking.
                    </div>
                  </center>
                )}
                {/* {succes?.status === "reserved" && (
                  <center>
                    <b style={{ fontSize: 20 }}>
                      Your selected table is reserved!
                    </b>
                    <br />
                    <br />
                    <div style={{ fontSize: 15 }}>
                      The seat will be available on <date and time>
                    </div>
                  </center>
                )} */}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

/*
  npm run build | tar.exe -a -c -f build.zip build/* | explorer . | git add . | git commit -m "Ordinery Push" | git push

  npm run build && tar -czf build.tar.gz build/* && open . && git add . && git commit -m "Ordinary Push" && git push
*/
